import { cn } from '@/utils';
export const MusixmatchLogo = ({
  className
}) => <svg className={cn(className)} viewBox="0 0 402 68" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MusixmatchLogo" data-sentry-source-file="MusixmatchProLogo.jsx">
    <path d="M67.7785 1.18748C63.9524 -0.718787 59.4594 -0.308691 56.048 2.26455L36.9898 16.6224L17.9271 2.26455C14.5156 -0.304185 10.0226 -0.718787 6.19651 1.18748C2.37495 3.09375 0 6.93333 0 11.2055V56.6541C0 60.9443 2.38847 64.7884 6.23256 66.6902C7.81886 67.4743 9.51783 67.8619 11.2078 67.8619C13.6098 67.8619 15.9892 67.0777 17.9901 65.55L36.9898 51.0344L55.9849 65.55C57.9858 67.0777 60.3652 67.8619 62.7672 67.8619C64.4572 67.8619 66.1562 67.4743 67.7425 66.6902C71.5865 64.7884 73.975 60.9443 73.975 56.6541V11.2055C73.975 6.93333 71.6001 3.09375 67.7785 1.18748ZM6.44437 11.2055C6.44437 9.36236 7.42679 7.77606 9.07619 6.95587C9.76569 6.60886 10.4912 6.44212 11.2123 6.44212C12.2128 6.44212 13.1952 6.76659 14.0514 7.41103L31.681 20.6918L19.9685 29.768L6.44437 19.3263V11.2055ZM6.44437 27.4652L14.7049 33.8464L6.44437 40.2502V27.4652ZM14.0785 60.4306C12.6093 61.5527 10.7436 61.7284 9.08971 60.9128C7.4313 60.0926 6.44437 58.5018 6.44437 56.6541V48.4026L19.9775 37.9159L30.1759 45.7888L31.6991 46.965L14.0785 60.4306ZM36.9853 42.9046L35.9848 42.134L25.2412 33.8374L36.9853 24.7297L48.7338 33.8374L37.6928 42.3638L36.9853 42.9046ZM67.5306 56.6541C67.5306 58.5018 66.5437 60.0926 64.8853 60.9128C63.2314 61.733 61.3657 61.5527 59.8966 60.4306L42.2759 46.965L43.7 45.8654L53.9975 37.9159L67.5306 48.4026V56.6541ZM67.5306 40.2502L59.2701 33.8464L67.5306 27.4652V40.2502ZM67.5306 19.3263L54.0065 29.768L42.294 20.6918L59.9236 7.41103C61.3927 6.30241 63.2539 6.13117 64.8988 6.95587C66.5482 7.77606 67.5306 9.36236 67.5306 11.2055V19.3263Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M137.333 45.4931C131.835 45.4931 126.999 41.9645 126.999 35.2857V22.9648H133.448V35.2857C133.448 37.7238 134.647 39.5039 137.333 39.5039C140.018 39.5039 141.217 37.7238 141.217 35.2857V22.9648H147.666V35.2857C147.666 41.969 142.831 45.4931 137.333 45.4931Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M149.406 39.0028L155.03 37.5157C155.319 39.129 156.558 40.535 158.712 40.535C160.614 40.535 161.358 39.4625 161.358 38.6333C161.358 37.2272 160.159 36.8126 157.843 36.4837C153.665 35.9068 149.861 34.5008 149.861 29.4985C149.861 25.4877 153.913 22.4277 158.586 22.4277C164.332 22.4277 166.856 26.1051 167.311 28.5882L161.732 29.9942C161.403 28.5071 160.366 27.3894 158.505 27.3894C156.932 27.3894 155.981 28.2141 155.981 29.2101C155.981 30.535 157.058 30.9046 159.411 31.1524C163.669 31.6481 167.68 33.1353 167.68 38.2232C167.68 41.9862 164.747 45.5418 158.708 45.5418C152.218 45.5418 149.776 41.3643 149.406 39.0118V39.0028Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M173.274 11.2607C175.55 11.2607 177.325 13.0408 177.325 15.2716C177.325 17.5023 175.55 19.2418 173.274 19.2418C171.124 19.2418 169.304 17.4663 169.304 15.2716C169.304 13.0769 171.124 11.2607 173.274 11.2607ZM170.092 45.0373H176.582V22.9597H170.092V45.0373Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M185.287 45.0385H178.135L186.071 33.9164L178.383 22.9609H185.616L189.586 29.2025L193.678 22.9609H200.789L193.015 33.7947L200.996 45.0385H193.763L189.545 38.5491L185.287 45.0385Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M249.535 45.4511C243.127 45.4511 239.035 39.9126 239.035 33.9594C239.035 28.3758 243.253 22.5488 249.621 22.5488C251.663 22.5488 254.587 22.9454 256.277 24.5858H256.318V22.9634H262.726V45.041H256.318V43.4142H256.277C254.348 45.343 250.531 45.4511 249.54 45.4511H249.535ZM251.023 28.3758C248.003 28.3758 245.565 30.6516 245.565 33.9594C245.565 37.2672 248.003 39.6241 251.023 39.6241C254.042 39.6241 256.48 37.3078 256.48 33.9594C256.48 30.611 254.042 28.3758 251.023 28.3758Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M275.485 36.9355C275.485 38.6299 276.436 39.7476 278.586 39.7476C279.537 39.7476 279.704 39.707 280.862 39.5808V44.831C279.375 45.2861 277.761 45.3267 277.018 45.3267C270.195 45.3267 269.037 40.8607 269.037 37.2645V28.5398H264.737V22.9607H269.118V16.0566H275.485V22.9607H280.943V28.5398H275.485V36.931V36.9355Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M281.353 34.0422C281.353 27.5122 286.603 22.4648 293.385 22.4648C297.973 22.4648 302.975 24.9434 304.093 29.9908L298.221 31.7709C297.725 30.117 296.03 28.3819 293.259 28.3819C290.487 28.3819 287.968 30.4504 287.968 33.9656C287.968 37.2328 290.118 39.5897 293.299 39.5897C296.111 39.5897 297.765 37.8547 298.383 36.1602L304.255 37.9403C302.849 43.0237 297.973 45.5474 293.218 45.5474C286.274 45.5474 281.353 40.6262 281.353 34.0557V34.0422Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M320.435 32.8018C320.435 30.4044 319.236 28.5026 316.591 28.5026C313.946 28.5026 312.621 30.6117 312.621 32.7207V45.0416H306.172V11.3867H312.621V25.5688H312.661C314.522 22.9235 317.582 22.5494 318.741 22.5494C324.32 22.5494 326.843 26.7676 326.843 31.3147V45.0416H320.435V32.8064V32.8018Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M110.541 32.7172V45.0381H104.133V32.8029C104.133 30.4054 103.182 28.5036 100.537 28.5036C97.8914 28.5036 96.6071 30.2792 96.6071 32.8029V45.0381H90.1582V22.9606H96.4854V24.9164H96.5665C98.0672 23.3571 100.145 22.4648 102.439 22.4648C105.084 22.4648 107.355 23.3031 108.527 25.0381C109.847 23.3166 112.294 22.4648 115.034 22.4648C120.451 22.4648 124.552 25.8538 124.552 31.2706V45.0381H118.103V32.7172C118.103 30.3198 117.152 28.4991 114.507 28.4991C111.862 28.4991 110.537 30.1936 110.537 32.7172H110.541Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M222.939 32.7172V45.0381H216.53V32.8029C216.53 30.4054 215.58 28.5036 212.934 28.5036C210.289 28.5036 209.005 30.2792 209.005 32.8029V45.0381H202.556V22.9606H208.883V24.9164H208.964C210.465 23.3571 212.542 22.4648 214.836 22.4648C217.481 22.4648 219.753 23.3031 220.924 25.0381C222.245 23.3166 224.692 22.4648 227.432 22.4648C232.849 22.4648 236.95 25.8538 236.95 31.2706V45.0381H230.501V32.7172C230.501 30.3198 229.55 28.4991 226.905 28.4991C224.259 28.4991 222.934 30.1936 222.934 32.7172H222.939Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M354.741 45.383C350.644 45.383 347.994 43.3145 346.219 40.5024V55.9373H342.992V22.9629H346.178V27.7624C347.958 24.9909 350.563 22.5889 354.745 22.5889C361.036 22.5889 365.128 28.2175 365.128 33.9679C365.128 39.7183 361.032 45.3875 354.745 45.3875L354.741 45.383ZM361.816 34.0445C361.816 29.4523 358.963 25.437 354.078 25.437C349.527 25.437 346.174 29.4929 346.174 33.9183C346.174 38.3438 349.401 42.5258 354.078 42.5258C358.756 42.5258 361.816 38.7178 361.816 34.0445Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M371.745 27.3424C373.399 23.9084 376.337 22.624 378.861 22.624C379.068 22.624 379.69 22.624 379.938 22.6646V26.0986C379.523 26.058 379.109 26.0175 378.699 26.0175C373.485 26.0175 371.79 30.3212 371.79 34.2509V45.0486H368.563V22.953H371.75V27.3379L371.745 27.3424Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
    <path d="M379.148 34.0847C379.148 27.5457 384.074 22.584 390.609 22.584C397.143 22.584 401.988 27.5908 401.988 33.9225C401.988 40.2542 396.981 45.4232 390.527 45.4232C384.074 45.4232 379.148 40.3758 379.148 34.0847ZM398.675 34.0847C398.675 29.452 395.322 25.3961 390.523 25.3961C385.723 25.3961 382.456 29.2852 382.456 33.918C382.456 38.5507 385.764 42.566 390.609 42.566C395.453 42.566 398.675 38.6769 398.675 34.0847Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MusixmatchProLogo.jsx" />
  </svg>;
export default MusixmatchLogo;