export const NOTIFICATION_CLEAR_TIMEOUT = 3000
export const NOTIFICATION_FETCH_TIMEOUT = 3 * 60 * 1000 // 3 minutes

export const NOTIFICATION_TYPES = {
  ACCELERATE: 'accelerate',
  ANALYSIS: 'analysis',
  LIMIT: 'limit',
  SUBMISSION: 'submission',
  SUBMISSION_ACCEPT: 'submission_accept',
  PLAN: 'plan',
  PUSH: 'push',
  PUSH_PRO: 'push_pro',
  VIP: 'vip'
}

export const getNotificationsProps = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.ACCELERATE:
      return {
        icon: 'Rocket',
        color: 'primary',
      }
    case NOTIFICATION_TYPES.ANALYSIS:
      return {
        icon: 'ChartLine',
        color: 'primary',
      }
    case NOTIFICATION_TYPES.LIMIT:
      return {
        icon: 'TriangleAlert',
        color: 'orange',
      }
    case NOTIFICATION_TYPES.SUBMISSION:
      return {
        icon: 'ArrowRightToLine',
        color: 'primary',
      }
    case NOTIFICATION_TYPES.SUBMISSION_ACCEPT:
      return {
        icon: 'Check',
        color: 'green',
      }
    case NOTIFICATION_TYPES.PLAN:
      return {
        icon: 'DollarSign',
        color: 'green',
      }
    case NOTIFICATION_TYPES.PUSH:
      return {
        icon: 'Bell',
        color: 'primary',
      }
    case NOTIFICATION_TYPES.PUSH_PRO:
      return {
        icon: 'UserPlus',
        color: 'blue',
      }
    case NOTIFICATION_TYPES.VIP:
      return {
        icon: 'Star',
        color: 'gold',
      }
    default:
      return {
        icon: 'Bell',
        color: 'primary',
      }
  }
}