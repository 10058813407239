export const CURATOR_PROMOTION_MIN_WEEK = 1
export const CURATOR_PROMOTION_MAX_WEEK = 10
export const CURATOR_PROMOTION_COST_PER_WEEK = 10
export const CURATOR_BIO_LIMIT = 500
export const CURATOR_STATS_DAYS_RANGE = 90
export const CURATOR_RANGE = {
  HIGHEST: 0.8,
  HIGH: 0.6,
  MEDIUM: 0.4,
  LOW: 0.2,
  LOWEST: 0.1,
}