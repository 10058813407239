import { nanoid } from 'nanoid'
import {
  PUSH_PRO_CHAT_MAX_DURATION_DAYS,
  PUSH_PRO_MIN_CASH_OUT_AMOUNT
} from './pushPro'

export const FAQ_KEYS = {
  DEFAULT: 'default',
  DISTRIBUTION: 'distribution',
  REVENUE_SHARE_ARTIST: 'revenue_share_artist',
  REVENUE_SHARE_CURATOR: 'revenue_share_curator',
}

export const TUTORIALS = {
  WELCOME: 'https://www.youtube.com/watch?v=sVwc7wDY-Vc',
  PLANS: 'https://www.youtube.com/watch?v=PVT4b1jT-OU',
  PROFILE: 'https://www.youtube.com/watch?v=ri7YtlcXleQ',
  PUSH_TO_PLAYLIST: 'https://www.youtube.com/watch?v=K4rGN9kXTzA',
  PROMOTE_CURATOR: 'https://www.youtube.com/watch?v=ayIqxjvuT58',
  DISTRIBUTION: 'https://www.youtube.com/watch?v=_EB9ByBQUfI',
  CREATE_RELEASE: 'https://www.youtube.com/watch?v=6XmwkEw0jUw',
}

export const FAQ_DATA_DEFAULT = (t) => {
  return [
    {
      question: 'faq.what_is',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.WELCOME} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.is_song_submitted',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_check_submissions',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/all-my-profile-tabs-explained/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_know_song_added',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/all-my-profile-tabs-explained/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_get_in_touch_curator',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_many_plays',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_match_song',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/here-is-matchfy-what-it-is-and-why-you-should-use-it/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_export_curator',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/exporting-contacts-is-easy/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PUSH_TO_PLAYLIST} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_be_curator',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/playlist-curators-as-influencers-why-you-should-be-one/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PROMOTE_CURATOR} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.what_featured_playlists',
      values: {
        blog: (
          <a key={nanoid()} href="https://blog.matchfy.io/playlist-curators-as-influencers-why-you-should-be-one/">
            {t('faq.blog')}
          </a>
        ),
        video: (
          <a key={nanoid()} href={TUTORIALS.PROMOTE_CURATOR} target="_blank" rel="noreferrer">
            {t('faq.video')}
          </a>
        ),
      },
    },
    {
      question: 'faq.how_know_compatible_genres',
    },
    {
      question: 'faq.what_do_curator_charge_money',
    },
    {
      question: 'faq.how_many_money_charge',
    },
    {
      question: 'faq.how_know_right_genres',
    },
    {
      question: 'faq.is_data_safe',
    },
    {
      question: 'faq.what_is_vip',
    },
    {
      question: 'faq.subscription',
    },
    {
      question: 'faq.refund_policy',
    },
    {
      question: 'faq.credits',
    },
    {
      question: 'faq.credits_policy',
    },
    {
      question: 'faq.accelerate',
    },
    {
      question: 'faq.promote_profile',
    },
    {
      question: 'faq.login_with_multiple_accounts',
    }
  ]
}

export const FAQ_DATA_REVENUE_SHARE_ARTIST = (t) => {
  const values = {
    feedbackDurationDays: PUSH_PRO_CHAT_MAX_DURATION_DAYS,
    minCashOutAmount: PUSH_PRO_MIN_CASH_OUT_AMOUNT,
  }

  return [
    { question: 'faq.revenue_share_artist.qa.0', values },
    { question: 'faq.revenue_share_artist.qa.1', values },
    { question: 'faq.revenue_share_artist.qa.2', values },
    { question: 'faq.revenue_share_artist.qa.3', values },
    { question: 'faq.revenue_share_artist.qa.4', values },
    { question: 'faq.revenue_share_artist.qa.5', values },
  ]
}

export const FAQ_DATA_REVENUE_SHARE_CURATOR = (t) => {
  const values = {
    feedbackDurationDays: PUSH_PRO_CHAT_MAX_DURATION_DAYS,
    minCashOutAmount: PUSH_PRO_MIN_CASH_OUT_AMOUNT,
  }

  return [
    { question: 'faq.revenue_share_curator.qa.0', values },
    { question: 'faq.revenue_share_curator.qa.1', values },
    { question: 'faq.revenue_share_curator.qa.2', values },
    { question: 'faq.revenue_share_curator.qa.3', values },
    { question: 'faq.revenue_share_curator.qa.4', values },
    { question: 'faq.revenue_share_curator.qa.5', values },
  ]
}

export const FAQ_DATA_DISTRIBUTION = (t) => {
  return [
    { question: 'faq.distribution.how_it_works' },
    { question: 'faq.distribution.self_management' },
    { question: 'faq.distribution.release_time' },
    { question: 'faq.distribution.royalties_percentage' },
    { question: 'faq.distribution.covers_and_remixes' },
    { question: 'faq.distribution.artist_vs_agency' },
    { question: 'faq.distribution.change_plan' },
    { question: 'faq.distribution.accelerate_program' },
    { question: 'faq.distribution.distribution_cost' },
    { question: 'faq.distribution.monitor_earnings' },
    { question: 'faq.distribution.release_types' },
    { question: 'faq.distribution.release_date' },
    { question: 'faq.distribution.music_removal' },
    { question: 'faq.distribution.withdraw_music' },
    { question: 'faq.distribution.customer_support' },
  ]
}

export const getFaqs = (t, key) => {
  if (key === FAQ_KEYS.REVENUE_SHARE_ARTIST) {
    return FAQ_DATA_REVENUE_SHARE_ARTIST(t)
  }

  if (key === FAQ_KEYS.REVENUE_SHARE_CURATOR) {
    return FAQ_DATA_REVENUE_SHARE_CURATOR(t)
  }

  if (key === FAQ_KEYS.DISTRIBUTION) {
    return FAQ_DATA_DISTRIBUTION(t)
  }

  return FAQ_DATA_DEFAULT(t)
}
