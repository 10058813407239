import { USER_TYPES } from './config'
import { ROUTES_DATA } from './routesData'

export const ROUTES = Object.fromEntries(
  Object.entries(ROUTES_DATA).map(([key, value]) => [key, value.path])
)

export const ROUTES_SEO = Object.values(ROUTES_DATA).filter((route) => route.seo)

const extractRoutes = (keys, config = {}) => keys
  .map((key) => {
    const route = ROUTES_DATA[key]
    if (!route) return null
    return {
      ...route,
      ...config[key]
    }
  })
  .filter(Boolean)

export const generateRoute = (path, params = {}) => {
  if (!path) return null
  Object.entries(params).forEach(([param, value]) => {
    path = path.replace(new RegExp(`\\[${param}\\]`, 'g'), value)
  })
  return path
}

// Navigation Menu
export const NAV = extractRoutes([
  'COMMUNITY',
  'PRICING',
  'REVENUE_SHARE_ARTIST'
])

// Profile Navigation
export const PROFILE_NAV = extractRoutes([
  'DASHBOARD',
  'PUBLIC_PROFILE',
  'ARTISTS',
  'SUBMISSIONS',
  'PLAYLISTS',
  'PRO',
  'REVENUE_SHARE_CURATOR',
  'DISTRIBUTION',
  'SETTINGS',
], {
  PRO: {
    roles: [USER_TYPES.PRO],
    new: true
  },
  REVENUE_SHARE_CURATOR: {
    roles: [
      USER_TYPES.USER,
      USER_TYPES.STAR,
      USER_TYPES.FEATURED
    ],
    new: true
  },
})

// Footer Navigation
export const FOOTER_NAV_1 = extractRoutes([
  'MATCH',
  'ACCELERATE',
  'ANALYSIS',
  'PRICING',
  'DISTRIBUTION',
  'TRACKS',
  'REVENUE_SHARE_CURATOR'
])

export const FOOTER_NAV_2 = extractRoutes([
  'AFFILIATE',
  'BLOG',
  'TEAM',
  'FAQ',
  'TUTORIALS',
  'SUPPORT',
])

// Sidebar Navigation
export const SIDEBAR_PRIMARY = [
  extractRoutes([
    'DASHBOARD',
    'PROFILE',
    'PUBLIC_PROFILE',
    'PLAYLISTS',
    'PRO',
    'CHAT'
  ]),
  {
    groupTitle: 'navigation.match',
    groupId: 'match',
    items: extractRoutes([
      'MATCH',
      'MAGAZINE',
      'TIKTOK',
      'RADIO',
    ])
  },
  {
    groupTitle: 'navigation.artist',
    groupId: 'artist',
    items: extractRoutes([
      'ARTISTS',
      'SUBMISSIONS',
      'VIP_CAMPAIGNS',
      'WANTED',
    ])
  },
  {
    groupTitle: 'navigation.tools',
    groupId: 'tools',
    items: extractRoutes([
      'ACCELERATES',
      'ANALYSIS',
      'CERTIFICATIONS',
      'DOWNLOADS',
      'DISTRIBUTION',
      'MANAGEMENT',
      'PLAYLIST_CHECK',
      'VIP',
      'PARTNERS'
    ])
  },
  {
    groupTitle: 'navigation.community',
    groupId: 'community',
    items: extractRoutes([
      'COMMUNITY',
      'POPULAR',
      'RANK',
    ])
  },
  {
    groupTitle: 'navigation.account',
    groupId: 'account',
    items: extractRoutes([
      'ACCOUNT',
      'BILLING',
      'SETTINGS',
    ])
  },
]

// Sidebar secondary
export const SIDEBAR_SECONDARY = extractRoutes([
  'SUPPORT'
])

// Mobile Tab Navigation
export const MOBILE_NAV = extractRoutes([
  'MATCH',
  'SUBMISSIONS',
  'PLAYLISTS',
  'DISTRIBUTION'
])

// Mobile Drawer
export const MOBILE_DRAWER = [
  {
    groupTitle: 'navigation.home',
    groupId: 'home',
    items: extractRoutes([
      'HOME',
      'PRICING',
      'DASHBOARD',
      'PROFILE',
      'MATCH',
      'PLAYLISTS',
      'REVENUE_SHARE_ARTIST',
    ]),
  },
  {
    groupTitle: 'navigation.artist',
    groupId: 'artist',
    items: extractRoutes([
      'ARTISTS',
      'SUBMISSIONS',
      'VIP_CAMPAIGNS',
      'WANTED',
    ])
  },
  {
    groupTitle: 'navigation.tools',
    groupId: 'tools',
    items: extractRoutes([
      'ACCELERATES',
      'ANALYSIS',
      'CERTIFICATIONS',
      'DOWNLOADS',
      'DISTRIBUTION',
      'MANAGEMENT',
      'PLAYLIST_CHECK',
      'PRO',
      'VIP',
      'PARTNERS'
    ])
  },
  {
    groupTitle: 'navigation.community',
    groupId: 'community',
    items: extractRoutes([
      'COMMUNITY',
      'POPULAR',
      'RANK',
    ])
  },
  {
    groupTitle: 'navigation.account',
    groupId: 'account',
    items: extractRoutes([
      'ACCOUNT',
      'BILLING',
      'SETTINGS',
    ])
  },
  {
    groupTitle: 'navigation.support',
    groupId: 'support',
    items: extractRoutes([
      'TUTORIALS',
      'FAQ',
      'SUPPORT',
    ])
  }
]

// Match Channels
export const MATCH_CHANNELS = extractRoutes([
  'SPOTIFY',
  'MAGAZINE',
  'TIKTOK',
  'RADIO',
])

// Rank Dropdown
export const RANK_DROPDOWN = extractRoutes([
  'RANK',
  'POPULAR',
])

// Language Options
export const DEFAULT_LANGUAGE = 'en'
export const AVAILABLE_LANGUAGES = [
  { label: 'languages.italian', value: 'it', bpc47: 'it-IT' },
  { label: 'languages.english', value: 'en', bpc47: 'en-US' },
  { label: 'languages.spanish', value: 'es', bpc47: 'es-ES' },
  { label: 'languages.french', value: 'fr', bpc47: 'fr-FR' },
]

// Login and Logout Redirects
export const LOGIN_REDIRECT = ROUTES_DATA.DASHBOARD.path
export const LOGOUT_REDIRECT = ROUTES_DATA.HOME.path
