export const socials = {
  instagram: {
    prefix: 'https://instagram.com/',
    icon: 'Instagram',
    iconType: 'brand',
  },
  twitter: {
    prefix: 'https://twitter.com/',
    icon: 'Twitter',
    iconType: 'brand',
  },
  facebook: {
    prefix: 'https://facebook.com/',
    icon: 'Facebook',
    iconType: 'brand',
  },
  linkedin: {
    prefix: 'https://linkedin.com/',
    icon: 'LinkedIn',
    iconType: 'brand',
  },
  tiktok: {
    prefix: 'https://tiktok.com/@',
    icon: 'TikTok',
    iconType: 'brand',
  },
  youtube: {
    prefix: 'https://youtube.com/@',
    icon: 'Youtube',
    iconType: 'brand',
  },
  website: {
    prefix: 'https://',
    icon: 'Link',
  },
}

export const removeSocialPrefixes = (userContacts) => {
  const contacts = {}

  Object.keys(socials).forEach((key) => {
    if (!userContacts?.[key]) {
      return
    }

    if (
      userContacts[key] &&
      userContacts[key] !== '' &&
      userContacts[key].charAt(0) === '@'
    ) {
      return userContacts[key].replace(new RegExp('@', 'g'), '')
    }
    if (
      userContacts[key] &&
      userContacts[key] !== '' &&
      userContacts[key].includes(socials[key].prefix)
    ) {
      contacts[key] = userContacts[key].replace(
        new RegExp(socials[key].prefix, 'g'),
        ''
      )
    }
  })

  return {
    ...userContacts,
    ...contacts,
  }
}

export const removeSpaces = (value) => {
  return value.replace(/\s+/g, '')
}

export const removeSocialPrefix = (value) => {
  try {
    const { pathname } = new URL(value)
    if (/in|channel/.test(pathname)) {
      return removeSpaces(pathname.split('/').filter(Boolean).join('/'))
    }

    return removeSpaces(pathname.replace(new RegExp('@|/', 'g'), ''))
  } catch (e) {
    return removeSpaces(value)
  }
}

export const removeWebsiteProtocol = (value) => {
  try {
    const { protocol } = new URL(value)
    return value.replace(new RegExp(`${protocol}//`), '')
  } catch (e) {
    return value
  }
}

export const appendSocialPrefixes = (userContacts) => {
  const contacts = {}

  Object.keys(socials).forEach((key) => {
    if (!userContacts?.[key]) {
      return
    }

    if (
      userContacts[key] &&
      userContacts[key] !== '' &&
      !userContacts[key].includes(socials[key].prefix)
    ) {
      contacts[key] = socials[key].prefix + userContacts[key]
    }
  })

  return {
    ...userContacts,
    ...contacts,
  }
}

export const isSpotifyUrl = (str) => {
  const regex = /^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/
  return regex.test(str)
}

export const getSpotifyId = (url) => {
  const regex =
    /https:\/\/open\.spotify\.com(?:\/[a-zA-Z-]*)?(\/(?:track|album|artist|playlist)\/)([^?/]+)/
  const match = url.match(regex)
  if (match && match[2] !== undefined) {
    return {
      type: match[1].split('/')[1],
      id: match[2],
    }
  } else {
    return null
  }
}

export const getUsername = (input) => {
  if (!input) {
    return null
  }

  if (input.includes('/')) {
    const parts = input.split('/')
    return parts.pop() || parts.pop()
  }

  return input
}

export const getYouTubeId = (input) => {
  const youtubePattern = /\/(c\/|channel\/)/

  if (youtubePattern.test(input)) {
    const parts = input.split(youtubePattern)
    return parts[2].split('/')[0]
  } else if (input.includes('/')) {
    const parts = input.split('/')
    return parts.pop() || parts.pop()
  }

  return input
}

export const getPlatformUrl = (platform, link) => {
  if (platform === 'spotify') {
    return `https://open.spotify.com/${getUsername(link)}`
  }
  if (platform === 'youtube') {
    return `https://www.youtube.com/${getYouTubeId(link)}`
  }
  if (platform === 'soundcloud') {
    return `https://soundcloud.com/${getUsername(link)}`
  }
  if (platform === 'tiktok') {
    return `https://www.tiktok.com/@${getUsername(link)}`
  }
  if (platform === 'instagram') {
    return `https://www.instagram.com/${getUsername(link)}`
  }
  if (platform === 'twitter') {
    return `https://twitter.com/${getUsername(link)}`
  }
  if (platform === 'facebook') {
    return `https://www.facebook.com/${getUsername(link)}`
  }
  if (platform === 'mixcloud') {
    return `https://www.mixcloud.com/${getUsername(link)}`
  }
  if (platform === 'bandcamp') {
    return `https://${getUsername(link)}.bandcamp.com`
  }
  if (platform === 'beatport') {
    return `https://www.beatport.com/${getUsername(link)}`
  }
  if (platform === 'residentadvisor') {
    return `https://www.residentadvisor.net/${getUsername(link)}`
  }
  if (platform === 'discogs') {
    return `https://www.discogs.com/${getUsername(link)}`
  }
  if (platform === 'website' || platform === 'linkedin') {
    return link
  }
}

export const buildSpotifyArtistUrl = (artistId) => {
  return `https://open.spotify.com/artist/${artistId}`
}

export const buildSpotifyPlaylistUrl = (playlistId) => {
  return `https://open.spotify.com/playlist/${playlistId}`
}

export const buildSpotifyTrackUrl = (trackId) => {
  return `https://open.spotify.com/track/${trackId}`
}

export const buildSpotifyUserUrl = (userId) => {
  return `https://open.spotify.com/user/${userId}`
}
