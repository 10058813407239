export const CHAT_TYPES = {
  PRO: 'pro',
  USER: 'user',
}

export const CHAT_FILTERS = {
  ALL: 'all',
  UNREAD: 'unread',
  PRO: 'pro',
}

export const CHAT_STATUSES = {
  ACTIVE: 'active',
  FROZEN: 'frozen',
}

export const CHAT_MESSAGES_PER_PAGE = 50
export const CHAT_SYNC_INTERVAL = 10000