export const STATUSES = {
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  COMPLETED: 'completed',
  DISPUTE: 'dispute',
  DISPUTE_UNDO: 'dispute_undo',
  IN_PROGRESS: 'in_progress',
  PAID: 'paid',
  PENDING: 'pending',
  REFUNDED: 'refunded'
}
