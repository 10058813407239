import { WANTED_URL } from './config'
import { EvenLogo, SongstatsLogo, WantedLogo } from "@/components/ui/Logo"

export const COMING_SOON_COLLABS = 6

export const EVEN_SIGNUP_URL = 'https://www.even.biz/backstage/auth/login?party=matchfy'
export const SONGSTATS_SIGNUP_URL = 'https://www.songstats.com/signup?rel=matchfy'

export const COLLABS = [
  {
    id: 'even',
    title: 'EVEN',
    logo: <EvenLogo />,
    cta: `${EVEN_SIGNUP_URL}?rel=matchfy`,
    featured: true,
    show: true,
  },
  {
    id: 'wanted',
    title: 'Wanted.Live',
    logo: <WantedLogo />,
    cta: `${WANTED_URL}?rel=matchfy`,
    featured: true,
    show: true,
  },
  {
    id: 'songstats',
    title: 'Songstats',
    logo: <SongstatsLogo />,
    cta: `${SONGSTATS_SIGNUP_URL}?rel=matchfy`,
    featured: false,
    show: false,
  }
]
