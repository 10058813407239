import { cn } from '@/utils';
const WantedLogo = ({
  className
}) => <svg className={cn(className)} viewBox="0 0 843 119" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="WantedLogo" data-sentry-source-file="WantedLogo.jsx">
    <path d="M74.7823 118.586H25.423L0.654877 0.585815H37.8071L47.7143 60.6425L50.8988 87.2366H53.1987L57.9754 60.6425L73.8977 0.585815H123.965L138.472 60.6425L142.718 87.2366H145.018L148.556 60.6425L159.702 0.585815H196.146L169.078 118.586H119.719L107.335 68.0396L99.5504 31.0545H97.2505L88.9355 68.0396L74.7823 118.586Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
    <path d="M233.307 118.586H195.977L247.106 0.585815H294.696L346.002 118.586H307.788L298.765 96.747H242.506L233.307 118.586ZM261.967 50.4276L255.067 66.9828H286.558L279.658 50.4276L271.874 30.5261H269.574L261.967 50.4276Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
    <path d="M382.822 118.586H349.562V0.585815H384.945L417.851 44.7918L435.189 71.9142H437.489L435.897 46.2007V0.585815H469.157V118.586H433.774L398.744 73.147L383.707 49.8992H381.407L382.822 72.4425V118.586Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
    <path d="M552.365 118.586H519.105V30.35H477.176V0.585815H594.117V30.35H552.365V118.586Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
    <path d="M707.414 118.586H602.15V0.585815H707.414V30.35H635.41V44.4395H704.761V74.0276H635.41V88.8216H707.414V118.586Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
    <path d="M778.788 118.586H718.814V0.585815H778.788C817.887 0.585815 842.655 22.2485 842.655 59.5858C842.655 96.9231 817.887 118.586 778.788 118.586ZM778.788 30.35H752.074V88.8216H778.788C799.841 88.8216 807.095 86.1798 807.095 59.5858C807.095 32.9918 799.841 30.35 778.788 30.35Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="WantedLogo.jsx" />
  </svg>;
export default WantedLogo;