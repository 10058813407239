import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

export const getDateFormat = (locale, includeTime = false, format) => {
  dayjs.locale(locale)

  if (format) {
    return format
  }

  if (locale === 'en') {
    if (includeTime) return 'MM/DD/YYYY HH:mm'
    return 'MM/DD/YYYY'
  }

  if (locale === 'es') {
    if (includeTime) return 'DD/MM/YYYY HH:mm'
    return 'DD/MM/YYYY'
  }

  if (locale === 'it') {
    if (includeTime) return 'DD/MM/YYYY HH:mm'
    return 'DD/MM/YYYY'
  }

  return 'YYYY-MM-DD'
}

export const getLongDateFormat = (locale, includeTime = false, format) => {
  dayjs.locale(locale)

  if (format) {
    return format
  }

  if (includeTime) {
    return 'LL LT'
  }

  return 'LL'
}

export const stripHtml = (html) => {
  return html.replace(/<[^>]*>?/gm, '')
}

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((n) => {
      return num >= n.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const truncate = (str, num = 150) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const readMore = (str, max = 30) => {
  const array = str.trim().split(' ')
  const ellipsis = array.length > max ? '...' : ''

  return array.slice(0, max).join(' ') + ellipsis
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const camelToKebab = (str) => {
  if (!str) return
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

export const addSpacesToCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1 $2')
    .trim()
}