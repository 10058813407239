import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { PUSH_PRO_CHAT_MAX_DURATION_DAYS } from '@/data'

dayjs.extend(duration)
dayjs.extend(relativeTime)

export const humanReadableDuration = ({
  value,
  locale,
  showFuture = false,
  t,
  threshold = 1 // 1 year
}) => {
  if (!value) return

  const inputDate = dayjs.isDayjs(value) ? value : dayjs().add(value, 'hours')
  const now = dayjs()
  const duration = dayjs.duration(showFuture ? now.diff(inputDate) : inputDate.diff(now))

  const years = Math.floor(duration.asYears())

  if (years >= threshold) {
    return t('common.labels.not_available')
  }

  const months = Math.floor(duration.asMonths())

  if (years >= 1) {
    return dayjs.duration({ years }).locale(locale).humanize(true)
  } else if (months >= 1) {
    return dayjs.duration({ months }).locale(locale).humanize(true)
  }

  return duration.locale(locale).humanize(true)
}

export const getPushProExpireDate = (createdAt) => {
  return dayjs(createdAt).add(PUSH_PRO_CHAT_MAX_DURATION_DAYS, 'days')
}

export const isPushProExpired = (createdAt) => {
  return dayjs().isAfter(getPushProExpireDate(createdAt))
}
