import { PLANS, USER_TYPES } from '@/data'

export const isPro = (type) => type === USER_TYPES.PRO
export const isStar = (type) => type === USER_TYPES.STAR
export const isUser = (type) => type === USER_TYPES.USER

export const isAgency = (user) => user?.plan?.name === PLANS.AGENCY
export const isArtist = (user) => user?.plan?.name === PLANS.ARTIST
export const isFree = (user) => user?.plan?.name === PLANS.FREE

export const notFree = (user) => user?.plan?.name !== PLANS.FREE
export const notArtist = (user) => user?.plan?.name !== PLANS.ARTIST
export const notAgency = (user) => user?.plan?.name !== PLANS.AGENCY

export const hasRole = (user, roles) => {
  if (!user) return false
  if (!roles) return true

  return roles.includes(user?.type)
}