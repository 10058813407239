import { PLANS, ROUTES } from '@/data'

export const planRedirect = (plan) => {
  const planParam = 'plan'

  switch (plan) {
    case PLANS.ARTIST:
      return `${ROUTES.PRICING}?${planParam}=${PLANS.ARTIST}`
    case PLANS.AGENCY:
      return `${ROUTES.PRICING}?${planParam}=${PLANS.AGENCY}`
    case PLANS.GEM_PACKAGE_50:
    case PLANS.GEM_PACKAGE_100:
    case PLANS.GEM_PACKAGE_200:
      return `${ROUTES.REVENUE_SHARE_ARTIST}?${planParam}=${plan}`
    default:
      return ROUTES.BILLING
  }
}
