import { STATUSES } from './status'

export const PUSH_PRO_MAX_FEEDBACK_PRICE = 10
export const PUSH_PRO_CHAT_MAX_DURATION_DAYS = 7
export const PUSH_PRO_MATCHFY_COMMISSION = 0.5
export const PUSH_PRO_GEM_CASH_OUT_RATE = 1
export const PUSH_PRO_MIN_CASH_OUT_AMOUNT = 50
export const PUSH_PRO_CASH_OUT_EMAIL = 'amministrazione@matchfy.io'

export const PUSH_PRO_TRACK_TYPES = [
  {
    id: 'released',
    icon: 'Music',
    iconColor: 'green',
    label: 'field.track.type.options.released.label',
    description: 'field.track.type.options.released.description',
  },
  {
    id: 'unreleased',
    icon: 'Music',
    iconColor: 'red',
    label: 'field.track.type.options.unreleased.label',
    description: 'field.track.type.options.unreleased.description',
  },
]

export const PUSH_PRO_FEEDBACK = [
  {
    id: 'generic_feedback',
    icon: 'MessageCircle',
    iconColor: 'orange',
    label: 'push_pro.feedback.generic_feedback.label',
    description: 'push_pro.feedback.generic_feedback.description',
  },
  {
    id: 'technical_optimization',
    icon: 'SlidersVertical',
    iconColor: 'red',
    label: 'push_pro.feedback.technical_optimization.label',
    description: 'push_pro.feedback.technical_optimization.description',
  },
  {
    id: 'management_music_business',
    icon: 'BriefcaseBusiness',
    iconColor: 'gold',
    label: 'push_pro.feedback.management_music_business.label',
    description: 'push_pro.feedback.management_music_business.description',
  },
  {
    id: 'collaborations_network',
    icon: 'BookUser',
    iconColor: 'blue',
    label: 'push_pro.feedback.collaborations_network.label',
    description: 'push_pro.feedback.collaborations_network.description',
  },
]

export const PUSH_PRO_REPORT = [
  {
    id: 'generic_or_copy_paste_response',
    icon: 'FileStack',
    iconColor: 'red',
    label: 'push_pro.report.reason.generic_or_copy_paste_response',
  },
  {
    id: 'not_descriptive_or_non_constructive',
    icon: 'UserMinus',
    iconColor: 'orange',
    label: 'push_pro.report.reason.not_descriptive_or_non_constructive',
  },
  {
    id: 'inappropriate_language',
    icon: 'VolumeX',
    iconColor: 'gold',
    label: 'push_pro.report.reason.inappropriate_language',
  },
  {
    id: 'other',
    icon: 'CircleEllipsis',
    iconColor: 'blue',
    label: 'push_pro.report.reason.other',
  },
]

export const PUSH_PRO_ARTIST_STEPS = [
  {
    icon: 'Gem',
    color: 'gold',
    counter: 1,
    title: 'revenue_share.artist.steps.0.title',
    description: 'revenue_share.artist.steps.0.description',
  },
  {
    icon: 'Send',
    color: 'blue',
    counter: 2,
    title: 'revenue_share.artist.steps.1.title',
    description: 'revenue_share.artist.steps.1.description',
  },
  {
    icon: 'MessageCircleReply',
    color: 'green',
    counter: 3,
    title: 'revenue_share.artist.steps.2.title',
    description: 'revenue_share.artist.steps.2.description',
  }
]

export const PUSH_PRO_CURATOR_STEPS = [
  {
    icon: 'BookCheck',
    color: 'blue',
    counter: 1,
    title: 'revenue_share.curator.steps.0.title',
    description: 'revenue_share.curator.steps.0.description',
  },
  {
    icon: 'MessageCircleReply',
    color: 'secondary',
    counter: 2,
    title: 'revenue_share.curator.steps.1.title',
    description: 'revenue_share.curator.steps.1.description',
  },
  {
    icon: 'BadgeEuro',
    color: 'green',
    counter: 3,
    title: 'revenue_share.curator.steps.2.title',
    description: 'revenue_share.curator.steps.2.description',
  }
]

export const getPushProSystemMessage = (status, _isCurator) => {
  const messageKey = `push_pro.chat_system.${status}`

  switch (status) {
    case STATUSES.IN_PROGRESS:
      return {
        color: 'blue',
        messageKey
      }
    case STATUSES.DISPUTE:
      return {
        color: 'orange',
        messageKey
      }
    case STATUSES.DISPUTE_UNDO:
      return {
        color: 'green',
        messageKey
      }
    case STATUSES.CLOSED:
      return {
        color: 'primary',
        messageKey
      }
    default:
      return {
        color: 'blue',
        messageKey
      }
  }
}
