import { CURRENCY, VAT_AMOUNT } from '@/data'

export const formatCurrency = (
  amount,
  locale = 'en-US',
  currency = CURRENCY
) => {
  switch (locale) {
    case 'en':
      locale = 'en-US'
      break
    case 'es':
      locale = 'es-ES'
      break
    case 'it':
      locale = 'it-IT'
      break
    default:
      locale = 'en-US'
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  const formatted = formatter.format(amount)
  return formatted
}

export const formatNumber = (number, locale = 'en-US') => {
  if (!number) return 0
  const formatter = new Intl.NumberFormat(locale)
  const formatted = formatter.format(number)
  return formatted
}

export const calculatePercentage = (number, total, round = 2) => {
  const percentage = (number / total) * 100
  if (round) return percentage.toFixed(round)
  return percentage
}

export const calculateVAT = (total, vatRate = VAT_AMOUNT) => {
  const vatAmount = total * (vatRate / 100)
  const totalWithVAT = total + vatAmount
  return totalWithVAT
}

export const calculateVATBreakdown = (total, vatRate = VAT_AMOUNT) => {
  const basePrice = total / (1 + vatRate / 100)
  const vatAmount = total - basePrice
  return {
    vatAmount,
    basePrice,
  }
}