import AppleMusicLogo from './AppleMusicLogo'
import AppleMusicLogoIcon from './AppleMusicLogoIcon'
import BeatportLogo from './BeatportLogo'
import BeatportLogoIcon from './BeatportLogoIcon'
import DeezerLogo from './DeezerLogo'
import DeezerLogoIcon from './DeezerLogoIcon'
import EvenLogo from './EvenLogo'
import FankeeLogo from './FankeeLogo'
import MusixmatchLogo from './MusixmatchLogo'
import MusixmatchProIcon from './MusixmatchProIcon'
import MusixmatchProLogo from './MusixmatchProLogo'
import NapsterLogo from './NapsterLogo'
import NapsterLogoIcon from './NapsterLogoIcon'
import PandoraLogo from './PandoraLogo'
import ShazamLogo from './ShazamLogo'
import ShazamLogoIcon from './ShazamLogoIcon'
import SongstatsLogo from './SongstatsLogo'
import SoundcloudLogo from './SoundcloudLogo'
import SoundcloudLogoIcon from './SoundcloudLogoIcon'
import SpotifyLogo from './SpotifyLogo'
import SpotifyLogoIcon from './SpotifyLogoIcon'
import TidalLogo from './TidalLogo'
import TidalLogoIcon from './TidalLogoIcon'
import WantedLogo from './WantedLogo'
import YouTubeLogo from './YouTubeLogo'
import YouTubeLogoIcon from './YouTubeLogoIcon'

export {
  AppleMusicLogo,
  AppleMusicLogoIcon,
  BeatportLogo,
  BeatportLogoIcon,
  DeezerLogo,
  DeezerLogoIcon,
  EvenLogo,
  FankeeLogo,
  MusixmatchLogo,
  MusixmatchProIcon,
  MusixmatchProLogo,
  NapsterLogo,
  NapsterLogoIcon,
  PandoraLogo,
  ShazamLogo,
  ShazamLogoIcon,
  SongstatsLogo,
  SoundcloudLogo,
  SoundcloudLogoIcon,
  SpotifyLogo,
  SpotifyLogoIcon,
  TidalLogo,
  TidalLogoIcon,
  WantedLogo,
  YouTubeLogo,
  YouTubeLogoIcon
}