export const SEARCH_TYPES = {
  NAVIGATION: 'navigation',
  CURATORS: 'curators',
  PLAYLISTS: 'playlists',
}

export const SEARCH_TABS = [
  {
    id: SEARCH_TYPES.NAVIGATION,
    label: 'common.labels.navigation',
  },
  {
    id: SEARCH_TYPES.CURATORS,
    label: 'navigation.curators.title',
  },
  {
    id: SEARCH_TYPES.PLAYLISTS,
    label: 'navigation.playlists',
  }
]